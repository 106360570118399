import { api } from './index';
const resource = '/sites';

export const getSites = async () => {
  try {
    const response = await api.get(`${resource}`);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getSiteInfo = async ({ id, payload }) => {
  try {
    const response = await api.post(`${resource}/events/${id}`, { ...payload });
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

// export const createSite = async (data) => {
//   try {
//     const payload = { ...data };
//     payload.face_blur = data.face_blur ? 1 : 0;
//     payload.full_body_blur = data.full_body_blur ? 1 : 0;
//     payload.stick_figure = data.stick_figure ? 1 : 0;
//     const response = await api.post(`${resource}`, payload);
//     return response.data;
//   } catch (error) {
//     throw new Error(error);
//   }
// };

export const createSite = async (data) => {
  try {
    const formData = new FormData();

    // Append all the regular fields
    Object.keys(data).forEach((key) => {
      if (key !== 'sitemapImage') {
        if (key === 'face_blur' || key === 'full_body_blur' || key === 'stick_figure') {
          formData.append(key, data[key] ? '1' : '0');
        } else {
          formData.append(key, data[key]);
        }
      }
    });

    // Append the image file
    if (data.sitemapImage) {
      formData.append('image', data.sitemapImage, data.sitemapImage.name);
    }

    const response = await api.post(`${resource}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

// export const updateSite = async ({ id, data }) => {
//   try {
//     const payload = { ...data };
//     payload.face_blur = data.face_blur ? 1 : 0;
//     payload.full_body_blur = data.full_body_blur ? 1 : 0;
//     payload.stick_figure = data.stick_figure ? 1 : 0;
//     const response = await api.put(`${resource}/${id}`, payload);
//     return response.data;
//   } catch (error) {
//     throw new Error(error);
//   }
// };

export const updateSite = async ({ id, data }) => {
  try {
    const formData = new FormData();

    // Handle all fields
    Object.keys(data).forEach((key) => {
      if (key !== 'sitemapImage') {
        if (key === 'face_blur' || key === 'full_body_blur' || key === 'stick_figure') {
          formData.append(key, data[key] ? '1' : '0');
        } else if (key === 'sitemap_config') {
          // Convert the object to JSON string before appending
          formData.append(key, JSON.stringify(data[key]));
        } else {
          formData.append(key, data[key]);
        }
      }
    });

    // Handle sitemapImage cases
    if (data.sitemapImage === null) {
      formData.append('sitemapImage', null);
    } else if (typeof data.sitemapImage === 'string') {
      // do nothing for existing image URLs
    } else if (data.sitemapImage) {
      formData.append('image', data.sitemapImage, data.sitemapImage.name);
    }

    const response = await api.put(`${resource}/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message);
  }
};
