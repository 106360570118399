/* eslint-disable */
import React from 'react';
import { Card, Badge } from '@shopify/polaris';
import viewCamera from '../assets/view-camera.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { getAppliedScenariosByCameraId } from '../api/scenarios.api';
import { DeleteMinor, EditMinor } from '@shopify/polaris-icons';
import { getCameraStatusLogs } from '../api/camera_status_logs.api';
import { useQuery } from '@tanstack/react-query';
import { dateToWordsTz } from '../utils';
import { calculateStartEndTime } from './CameraStatus/CameraStatusTracker';
import { useSelector } from 'react-redux';
import { Tracker } from '@tremor/react';

const CameraListCard = ({ camera }) => {
  const { search } = useLocation();

  const navigateTo = useNavigate();
  const timeRange = 'LAST_24_HOURS';
  const { user } = useSelector((state) => state.auth);

  const { mutate: doesCameraHaveAppliedScenarios } = useMutation({
    mutationFn: getAppliedScenariosByCameraId,
    onSuccess: async (resp) => {
      const searchObject = Object.fromEntries(new URLSearchParams(search));
      if (resp.data.length > 0) {
        const found = resp.data.find((sc) => {
          if (`${sc.scenario_name}` === searchObject.scenario) {
            return true;
          }
          return false;
        });

        if (found) {
          return navigateTo(
            `/apply-scenario?camera=${camera.name}&cameraId=${camera.id}&scenario=${searchObject.scenario}&appliedScenarioId=${found.id}`
          );
        }

        return navigateTo(
          `/apply-scenario?camera=${camera.name}&cameraId=${camera.id}&scenario=${searchObject.scenario}`
        );
      } else {
        return navigateTo(
          `/apply-scenario?camera=${camera.name}&cameraId=${camera.id}&scenario=${searchObject.scenario}`
        );
      }
    },
    onError: () => {
      return api.error({
        message: 'Unable to fetch applied scenarios. Please try again later.',
        placement: 'topRight'
      });
    }
  });

  const handleNavigation = () => {
    const searchObject = Object.fromEntries(new URLSearchParams(search));
    if (Object.keys(searchObject).length > 0) {
      return doesCameraHaveAppliedScenarios(camera.id);
    }
    return navigateTo(`/cameras/${camera.id}`, { state: { camera } });
  };
  const { data: cameraStatusLogs, isLoading: isCameraStatusLogLoading } = useQuery({
    queryKey: ['cameraStatusLogs', camera.name, timeRange],
    queryFn: () => getCameraStatusLogs({ cameraName: camera.name, timeRange })
  });

  const formatCameraDataForTracker = (cameraData, timeRange) => {
    const colorMapping = {
      online: 'emerald-500',
      offline: 'red-500'
    };

    const { startTime, endTime } = calculateStartEndTime(timeRange);

    // Format the transitions into data usable by the Tracker component
    const formattedData = [];

    cameraData.forEach((camera) => {
      const { transitions } = camera;

      transitions.forEach((transition) => {
        const transitionStart = new Date(transition.start_time);
        const transitionEnd =
          transition.end_time === 'now' ? endTime : new Date(transition.end_time);

        // If the transition is outside the time range, skip it
        if (transitionEnd <= startTime || transitionStart >= endTime) {
          return;
        }

        // Clip the transition times to fit within the time range
        const clippedStartTime = transitionStart < startTime ? startTime : transitionStart;
        const clippedEndTime = transitionEnd > endTime ? endTime : transitionEnd;

        let currentTime = new Date(clippedStartTime);
        while (currentTime < clippedEndTime) {
          formattedData.push({
            tooltip: dateToWordsTz(currentTime, user?.timezone ?? 'UTC'),
            status: transition.status,
            color: colorMapping[transition.status]
          });
          currentTime.setHours(currentTime.getHours() + 1);
        }
      });
    });

    return formattedData;
  };

  return (
    <Card padding="0">
      <div className="grid grid-cols-1 sm:grid-cols-12">
        <div className="sm:col-span-3">
          <div className="relative h-full">
            <img src={camera.thumbnail_url} alt="camera" className="w-full h-full object-cover" />
            {/* <div className="flex items-center gap-2 absolute top-3 left-3 bg-black bg-opacity-50 py-1.5 px-2 rounded-full">
              <span className="bg-green-500 w-2 h-2 rounded-full"></span>
              <span className="text-sm text-white">Online</span>
            </div> */}
          </div>
        </div>
        <div className="sm:col-span-9">
          <div className="bg-[#F7F4FF] py-3 px-3">
            <div className="flex items-center justify-between">
              <h2 className="font-semibold text-base">{camera.name}</h2>
            </div>
            <p className="text-xs pt-2 text-gray-500">
              Area:<span className="text-black"> {camera.area}</span>
            </p>
          </div>
          <div className="py-3 px-3">
            <h3 className="text-base text-black font-semibold">Scenarios & Events Applied</h3>
            <div className="flex flex-wrap justify-between gap-2 mt-4">
              {camera.applied_scenarios.map((scenario) => (
                <Badge key={scenario.id} tone="info" size="large">
                  {scenario.scenario_name}
                </Badge>
              ))}
              {!isCameraStatusLogLoading && cameraStatusLogs?.length > 0 && (
                <div className="flex items-center w-1/2">
                  <p className="text-sm text-gray-700 font-semibold mb-1 pr-2">Status</p>
                  <Tracker
                    data={formatCameraDataForTracker(cameraStatusLogs, timeRange)}
                    className="w-full h-6"
                  />
                </div>
              )}

              {isCameraStatusLogLoading && (
                <div className="text-gray-500">Loading camera status...</div>
              )}
            </div>

            <div className="flex flex-wrap justify-between gap-2 mt-4">
              <div className="flex items-center flex-wrap gap-2">
                {camera.applied_scenarios
                  .reduce((acc, curr) => {
                    const events = curr.selected_events.split(',');
                    return [...acc, ...events];
                  }, [])
                  .map((e, idx) => (
                    <Badge key={idx}>{e.charAt(0).toUpperCase() + e.slice(1)}</Badge>
                  ))}
              </div>

              <img
                onClick={() => navigateTo(`/cameras/${camera.id}`)}
                src={viewCamera}
                className="w-[30px] cursor-pointer"
              />
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default CameraListCard;
