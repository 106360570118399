import { Button, Spinner } from '@shopify/polaris';
import { Modal, notification } from 'antd';
import { useEffect, useState } from 'react';
import SiteForm from './SiteForm';
import { useMutation } from '@tanstack/react-query';
import { updateSite } from '../../api/sites.api';

const EditSiteModal = ({
  isOpen,
  handleCancel = () => {},
  isPending = false,
  chosenSiteData,
  setIsOpen,
  siteCallback
}) => {
  const [api, contextHolder] = notification.useNotification();
  const [isEmailValid, setIsEmailValid] = useState(true);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    addressLn1: '',
    addressLn2: '',
    state: '',
    zipcode: '',
    faceBlur: false,
    fullBodyBlur: false,
    stickFigure: false,
    sitemapImage: null
  });

  const onDrop = (files, rejections) => {
    setFormData({ ...formData, sitemapImage: files[0] });
    if (rejections.length) {
      console.warn(rejections);
    }
  };

  useEffect(() => {
    if (chosenSiteData) {
      setFormData({
        name: chosenSiteData.name,
        email: chosenSiteData.email,
        addressLn1: chosenSiteData.address_line_1,
        addressLn2: chosenSiteData.address_line_2,
        state: chosenSiteData.state,
        zipcode: chosenSiteData.zipcode,
        faceBlur: chosenSiteData.face_blur ? true : false,
        fullBodyBlur: chosenSiteData.full_body_blur ? true : false,
        stickFigure: chosenSiteData.stick_figure ? true : false,
        sitemapImage: chosenSiteData.sitemap_url
      });
    }
  }, [chosenSiteData]);

  const { mutate } = useMutation({
    mutationFn: updateSite,
    onSuccess: () => {
      setIsOpen(false);
      siteCallback.refetch();
    }
  });

  const handleOk = () => {
    if (
      !formData.addressLn1 ||
      !formData.state ||
      !formData.zipcode ||
      !formData.name ||
      !formData.email ||
      !isEmailValid
    ) {
      return api.warning({
        message: 'Make sure to fill all required fields'
      });
    }
    mutate({
      id: chosenSiteData.id,
      data: {
        ...formData,
        status: 1,
        address_line_1: formData.addressLn1,
        address_line_2: formData.addressLn2,
        face_blur: formData.faceBlur,
        full_body_blur: formData.fullBodyBlur,
        stick_figure: formData.stickFigure
      }
    });
  };

  return (
    <>
      {contextHolder}
      <Modal
        title="Edit Site"
        open={isOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ disabled: true }}
        cancelButtonProps={{ disabled: true }}
        maskClosable={false}
        footer={[
          <div className={`inline mr-2`} key="cancel">
            <Button variant="secondary" onClick={handleCancel}>
              Cancel{' '}
            </Button>
          </div>,
          <div className="inline mr-2" key="submit">
            <Button variant="primary" onClick={handleOk}>
              <div className="flex items-center">
                Save
                {isPending && (
                  <span className="ml-2">
                    <Spinner size="small" />
                  </span>
                )}
              </div>
            </Button>
          </div>
        ]}
      >
        <form>
          <SiteForm
            formData={formData}
            setFormData={setFormData}
            isEmailValid={isEmailValid}
            setIsEmailValid={setIsEmailValid}
            onDrop={onDrop}
          />
        </form>
      </Modal>
    </>
  );
};

export default EditSiteModal;
