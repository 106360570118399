/* eslint-disable */
import { Button, Spinner } from '@shopify/polaris';
import { useMutation, useQuery } from '@tanstack/react-query';
import { notification } from 'antd';
import Modal from 'antd/es/modal/Modal';
import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getCameras } from '../../api/cameras.api';
import { getSiteInfo, updateSite } from '../../api/sites.api';
import KonvaDraggableCanvas from '../../components/DraggableKonvaCanvas';
import { ReadOnlyKonvaCanvas } from '../../components/ReadOnlyKonvaCanvas';

export default function Sitemap({ site, selectedDates, selectedFilters, reference }) {
  const [api, contextHolder] = notification.useNotification();
  const { chosenSite } = useSelector((state) => state.auth);
  const [cameraIcons, setCameraIcons] = useState([]);
  const [initialCameraState, setInitialCameraState] = useState([]);
  const containerRef = useRef(null);
  const [parentSize, setParentSize] = useState({ width: 900, height: 529 });

  const [open, setOpen] = useState(false);
  const [scaledIcons, setScaledIcons] = useState([]);

  const originalSize = { width: 850, height: 500 }; // Original canvas size

  const scaleCoordinates = (icons, originalSize, newSize) => {
    return icons.map((icon) => ({
      ...icon,
      x: (icon.x / originalSize.width) * newSize.width,
      y: (icon.y / originalSize.height) * newSize.height
    }));
  };

  useEffect(() => {
    const updateSize = () => {
      if (containerRef.current) {
        const { width } = containerRef.current.getBoundingClientRect();
        const height = Math.round(width / 1.7); // Maintain the aspect ratio
        const newSize = { width, height };
        setParentSize(newSize);
        setScaledIcons(scaleCoordinates(cameraIcons, originalSize, newSize));
      }
    };

    updateSize(); // Initial size calculation

    const resizeObserver = new ResizeObserver(updateSize);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, [cameraIcons]); // Add cameraIcons as a dependency

  const { data, isLoading } = useQuery({
    queryKey: ['siteMapCameras'],
    queryFn: getCameras
  });

  const { mutate: modifySiteMap, isPending } = useMutation({
    mutationFn: updateSite,
    mutationKey: ['updateSiteMap'],
    onSuccess: () => {
      setOpen(false);
      refetchSites();
      api.success({
        message: 'Site Map Configured Successfully!',
        placement: 'topRight'
      });
    },
    onError: () => {
      api.error({
        message: 'Something went wrong! Please try again later.',
        placement: 'topRight'
      });
    }
  });

  const {
    data: siteInfo,
    isLoading: areSitesLoading,
    isError: didSitesError,
    refetch: refetchSites
  } = useQuery({
    queryKey: ['siteMapSites', selectedFilters.scenario, selectedFilters.timeRange, selectedDates],
    queryFn: () =>
      getSiteInfo({
        id: chosenSite.id,
        payload: {
          scenario: selectedFilters.scenario,
          timeRange: selectedFilters.timeRange,
          startDate: selectedDates.start,
          endDate: selectedDates.end
        }
      })
  });

  // useEffect(() => {
  //   if (siteInfo && !areSitesLoading && !didSitesError) {
  //     const requiredSite = siteInfo;
  //
  //     if (requiredSite.sitemap_config) {
  //       const icons = Object.keys(requiredSite.eventCount).map(
  //         (key) => requiredSite.eventCount[key]
  //       );
  //       setCameraIcons(icons);
  //       setInitialCameraState(icons);
  //       setScaledIcons(scaleCoordinates(icons, originalSize, parentSize));
  //     }
  //   } else if (didSitesError) {
  //     // Handle error
  //   }
  // }, [siteInfo, areSitesLoading, didSitesError, parentSize]);
  useEffect(() => {
    if (siteInfo && !areSitesLoading && !didSitesError) {
      // dispatch(setSites(sites));

      const requiredSite = siteInfo;

      if (requiredSite.sitemap_config) {
        setCameraIcons(
          Object.keys(requiredSite.eventCount).map((key) => requiredSite.eventCount[key])
        );
        setInitialCameraState(
          Object.keys(requiredSite.eventCount).map((key) => requiredSite.eventCount[key])
        );
      }
    } else if (didSitesError) {
      // dispatch(logout());
    }
  }, [siteInfo, areSitesLoading, didSitesError]);

  const handleCancel = () => {
    setOpen(false);
    setCameraIcons([...initialCameraState]);
    refetchSites();
  };

  return (
    <div className="mb-4 bg-white px-4 rounded-lg shadow-xl flex flex-col">
      {contextHolder}
      <div className="flex items-center justify-between text-xl">
        <div className="flex justify-between flex-wrap items-center lg:space-y-0 space-y-5">
          <h3 className="font-semibold text-[20px]">Site Map - {chosenSite.name}</h3>
        </div>
        {!isLoading && data && (
        <div style={{ flexShrink: 0 }}> {/* Wrapper to prevent stretching */}

          <Button
            onClick={() => {
              setOpen(true);
            }}
            variant="secondary"
            size="large"
      
          >
            Edit
            </Button>
          </div>
        )}
      </div>
      {/* <div className="w-full flex justify-center"> */}
      <div ref={containerRef} className="w-full flex justify-center" style={{ margin: 10 }}>
        <ReadOnlyKonvaCanvas
          reference={reference}
          selectedDates={selectedDates}
          selectedFilters={selectedFilters}
          backgroundImageUrl={site.sitemap_url}
          // canvasSize={{ width: 850, height: 500 }}
          canvasSize={parentSize}
          initialIcons={scaledIcons}
        />
      </div>
      <Modal
        open={open}
        width={1000}
        title="Configure your site map"
        onCancel={handleCancel}
        maskClosable={false}
        footer={[
          <span className="mr-1" key="back">
            <div style={{ 
              display: 'inline-block',
              width: 'auto',
              flexShrink: 0,
              flexGrow: 0,
              flexBasis: 'auto'
            }}>
              <Button variant="secondary" onClick={handleCancel}>
                Cancel
              </Button>
            </div>
          </span>,
          <span className="ml-1" key="submit">
            <div style={{ 
              display: 'inline-block',
              width: 'auto',
              flexShrink: 0,
              flexGrow: 0,
              flexBasis: 'auto'
            }}>
              <Button
                onClick={() => {
                  modifySiteMap({
                    id: chosenSite.id,
                    data: {
                      sitemap_config: { ...cameraIcons }
                    }
                  });
                }}
                disabled={isPending}
                variant="primary"
              >
                <div className="flex items-center">
                  <span>Submit</span>
                  {isPending && (
                    <span className="ml-2">
                      <Spinner size="small" />
                    </span>
                  )}
                </div>
              </Button>
            </div>
          </span>
        ]}
      >
        <KonvaDraggableCanvas
          selectedDates={selectedDates}
          selectedFilters={selectedFilters}
          icons={cameraIcons}
          setIcons={setCameraIcons}
          backgroundImageUrl={site.sitemap_url}
          cameras={data?.data?.map((cam) => ({
            label: cam.name,
            value: JSON.stringify({
              id: cam.id,
              name: cam.name
            })
          }))}
        />
      </Modal>
    </div>
  );
}
