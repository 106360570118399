import { api } from './index';

const resource = '/graphs';
export const getFiltersApi = async () => {
  try {
    const response = await api.get(`${resource}/filters`);
    const filters = response.data;

    // to convert to a format that can be used by Polaris's Select component
    const scenarios = filters.scenarios.map((scenario) => ({ label: scenario, value: scenario }));
    const cameras = filters.cameras.map((camera) => ({ label: camera, value: camera }));
    const areas = filters.areas.map((area) => ({ label: area, value: area }));
    const timeRange = [...filters.timeRanges, 'CUSTOM_DATE_RANGE'].map((range) => ({
      label: range
        .replace(/_/g, ' ')
        .toLowerCase()
        .replace(/\b\w/g, (char) => char.toUpperCase()),
      value: range
    }));

    return { scenarios, cameras, areas, timeRange };
  } catch (error) {
    console.log({ error });
    throw { message: error.message, status: error.status };
  }
};

export const getOverallStatsApi = async (filters) => {
  try {
    const queryString = Object.keys(filters)
      .map((key) => `${key}=${filters[key]}`)
      .join('&');
    const response = await api.get(`${resource}/statistics${queryString ? `?${queryString}` : ''}`);
    return response.data;
  } catch (error) {
    console.log({ error });
    throw { message: error.message, status: error.status };
  }
};

export const getEventsOverviewApi = async (filters) => {
  try {
    const queryString = Object.keys(filters)
      .map((key) => `${key}=${filters[key]}`)
      .join('&');
    const response = await api.get(
      `${resource}/event-bar-chart${queryString ? `?${queryString}` : ''}`
    );
    return response.data;
  } catch (error) {
    throw { message: error.message, status: error.status };
  }
};

export const getEventsByArea = async (filters) => {
  try {
    const queryString = Object.keys(filters)
      .map((key) => `${key}=${filters[key]}`)
      .join('&');
    const response = await api.get(
      `${resource}/event-count-by-area${queryString ? `?${queryString}` : ''}`
    );
    return response.data;
  } catch (error) {
    console.log({ error });
    throw { message: error.message, status: error.status };
  }
};

export const getEventsByCamera = async (filters) => {
  try {
    const queryString = Object.keys(filters)
      .map((key) => `${key}=${filters[key]}`)
      .join('&');
    const response = await api.get(
      `${resource}/event-count-by-camera${queryString ? `?${queryString}` : ''}`
    );
    return response.data;
  } catch (error) {
    console.log({ error });
    throw { message: error.message, status: error.status };
  }
};

export const getEventsBySeverity = async (filters) => {
  try {
    const queryString = Object.keys(filters)
      .map((key) => `${key}=${filters[key]}`)
      .join('&');
    const response = await api.get(
      `${resource}/severity-chart${queryString ? `?${queryString}` : ''}`
    );
    return response.data;
  } catch (error) {
    throw { message: error.message, status: error.status };
  }
};

export const getEventItemsBySeverity = async (filters) => {
  try {
    const queryString = Object.keys(filters)
      .filter((f) => filters[f] !== null)
      .map((key) => `${key}=${filters[key]}`)
      .join('&');
    const response = await api.get(
      `${resource}/events-by-severity${queryString ? `?${queryString}` : ''}`
    );
    return response.data;
  } catch (error) {
    throw { message: error.message, status: error.status };
  }
};

export const getLineChartApi = async (filters) => {
  try {
    const queryString = Object.keys(filters)
      .map((key) => `${key}=${filters[key]}`)
      .join('&');
    const response = await api.get(`${resource}/line-chart${queryString ? `?${queryString}` : ''}`);
    return response.data;
  } catch (error) {
    throw { message: error.message, status: error.status };
  }
};

export const generatePdf = async (payload) => {
  try {
    // const response = await api.post(`${resource}/generate-pdf`, payload, {
    //   responseType: 'blob'
    // });
    // upload images to cloudinary

    const response = await api.post(`${resource}/generate-pdf`, payload, {
      responseType: 'blob'
    });
    return response.data;
  } catch (error) {
    console.log({ error });
    throw { message: error.message, status: error.status };
  }
};
