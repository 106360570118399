import { useEffect, useState } from 'react';
import MultiSwitch from '../../components/MultiSwitch';
import { getCameras, addCamera } from '../../api/cameras.api';
import CameraHLS from '../../components/Camera-HLS';
import { useQuery, useMutation } from '@tanstack/react-query';
import { Spinner, Button, TextField } from '@shopify/polaris';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, notification } from 'antd';
import { isProtocolValid } from '../../utils';
import {
  resetScenarioFormData,
  resetScenarioPerCamFormData
} from '../../store/slices/scenarioSlice';
import CameraListCard from '../../components/CameraListCard';
import { getIntegrations } from '../../api/integrations.api';

const Cameras = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [viewIdx, setViewIdx] = useState(0);

  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    dispatch(resetScenarioFormData());
    dispatch(resetScenarioPerCamFormData());
  }, []);

  const changeView = (view) => {
    if (view === 'Grid') {
      setViewIdx(0);
    } else {
      setViewIdx(1);
    }
  };

  const [createCameraForm, setCreateCameraForm] = useState({
    cameraName: '',
    area: '',
    description: '',
    cameraSource: '',
    token: null,
    thumbnailUrl: null
  });

  const validateAndSetCreateCameraForm = (newCameraForm) => {
    // add character limit of 100 to cameraName, area, description
    if (
      newCameraForm.cameraName.length > 100 ||
      newCameraForm.area.length > 100 ||
      newCameraForm.description.length > 255
    ) {
      return;
    }

    const validPattern = /^[a-zA-Z0-9-]*$/;
    if (!validPattern.test(newCameraForm.cameraName)) {
      setInvalidCameraName(true);
      return;
    }

    setInvalidCameraName(false);
    setCreateCameraForm(newCameraForm);
  };

  const { mutate, isPending } = useMutation({
    mutationFn: addCamera,
    onSuccess: () => {
      setOpen(false);
      resetForm();
      refetch();
    },
    onError: (error) => {
      setAddCameraError(true);
      const errorMessage = error.message || 'An unexpected error occurred.';
      api.error({ message: errorMessage });
    },
    retryDelay: 1000
  });

  const [open, setOpen] = useState(false);
  const [addCameraError, setAddCameraError] = useState(false);
  const [invalidFieldsError, setInvalidFieldsError] = useState(false);
  const [invalidSourceError, setInvalidSourceError] = useState(false);
  const [invalidCameraName, setInvalidCameraName] = useState(false);

  const resetForm = () => {
    setCreateCameraForm({
      cameraName: '',
      area: '',
      description: '',
      cameraSource: '',
      token: null,
      thumbnailUrl: null
    });
    setAddCameraError(false);
    setInvalidCameraName(false);
    setInvalidFieldsError(false);
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    if (
      !createCameraForm.cameraName ||
      !createCameraForm.area ||
      !createCameraForm.description ||
      !createCameraForm.cameraSource ||
      (isSynologyIntegrationEnabled && !createCameraForm.token)
    ) {
      setInvalidCameraName(false);
      setInvalidSourceError(false);
      return setInvalidFieldsError(true);
    }
    if (!isProtocolValid(createCameraForm.cameraSource)) {
      setInvalidCameraName(false);
      setInvalidFieldsError(false);
      return setInvalidSourceError(true);
    }
    setInvalidFieldsError(false);
    setInvalidSourceError(false);
    mutate(createCameraForm);
  };

  const handleCancel = () => {
    setOpen(false);
    resetForm();
  };

  const {
    data: cameraData,
    isLoading: cameraLoading,
    refetch
  } = useQuery({
    queryKey: ['cameras'],
    queryFn: getCameras
  });

  const { data: integrationsData, isLoading: integrationsLoading } = useQuery({
    queryKey: ['integrations'],
    queryFn: getIntegrations
  });

  const isSynologyIntegrationEnabled = integrationsData?.some(
    (integration) => integration.name === 'Synology' && integration.status
  );

  if (cameraLoading || integrationsLoading) {
    return (
      <div className="grid place-items-center h-screen w-screen">
        <Spinner size="large" color="teal" accessibilityLabel="Loading Cameras" />
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      {contextHolder}
      <div className="flex items-center justify-end px-2 py-2 bg-white shadow-md mb-2">
        <span className="px-2">
          <MultiSwitch
            options={['Grid', 'List']}
            selectedOption={viewIdx}
            bgColors={['bg-black/10', 'bg-black/10']}
            textColors={['text-black', 'text-black']}
            onSelect={changeView}
            fullWidth={false}
          />
        </span>
      </div>
      <div>
        {viewIdx === 0 ? (
          cameraData && cameraData.data && cameraData.data.length === 0 ? (
            <div className="grid place-items-center h-[60vh]">
              <p>No Cameras Found</p>
            </div>
          ) : (
            <div className="mx-10">
              <div className="flex flex-wrap gap-x-4 gap-y-8 justify-center">
                {cameraData.data.map((camera) => (
                  <CameraHLS
                    key={camera.id}
                    camera={camera}
                    refetch={refetch}
                    isSynologyIntegrationEnabled={isSynologyIntegrationEnabled}
                  />
                ))}
              </div>
            </div>
          )
        ) : cameraLoading ? (
          <div className="grid place-items-center h-screen w-screen">
            <Spinner size="large" color="teal" accessibilityLabel="Loading Cameras" />
          </div>
        ) : cameraData && cameraData.data && cameraData.data.length === 0 ? (
          <div className="grid place-items-center h-[60vh]">
            <p>No Cameras Found</p>
          </div>
        ) : (
          <div className="mx-10">
            {/** 3-column layout where each camera occupies one row */}
            {cameraData.data.map((camera) => (
              <div className="my-8" key={camera.id}>
                <CameraListCard camera={camera} />
              </div>
            ))}
          </div>
        )}
      </div>
      {user.role_id !== 6 && ( // TODO: Change global-user access to a dynamic methodology
        <button
          className="fixed bottom-20 right-8 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full text-lg"
          aria-label="Add Camera"
          onClick={showModal}
        >
          +
        </button>
      )}

      <Modal
        title="Add Camera"
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ disabled: true }}
        cancelButtonProps={{ disabled: true }}
        maskClosable={false}
        footer={[
          <div className="inline mr-2" key="back">
            <Button variant="secondary" onClick={handleCancel}>
              Cancel
            </Button>
          </div>,
          <div className="inline mr-2" key="submit">
            <Button variant="primary" onClick={handleOk}>
              <div className="flex items-center">
                Submit
                {isPending && (
                  <span className="ml-2">
                    <Spinner size="small" />
                  </span>
                )}
              </div>
            </Button>
          </div>
        ]}
      >
        <form>
          <div className="my-4">
            <TextField
              value={createCameraForm.cameraName}
              onChange={(e) => {
                validateAndSetCreateCameraForm({ ...createCameraForm, cameraName: e });
              }}
              label={
                <span>
                  Camera Name <span className="text-red-500">*</span>
                </span>
              }
              placeholder="Visionify-Camera-1"
            />
          </div>
          {invalidCameraName && (
            <span className="text-red-500 text-xs mt-2">
              {' '}
              * Name cannot have any special characters.
            </span>
          )}
          <div className="my-4">
            <TextField
              value={createCameraForm.area}
              onChange={(e) => validateAndSetCreateCameraForm({ ...createCameraForm, area: e })}
              label={
                <span>
                  Area <span className="text-red-500">*</span>
                </span>
              }
              placeholder="Laundry Entrance"
            />
          </div>
          <div className="my-4">
            <TextField
              value={createCameraForm.description}
              onChange={(e) =>
                validateAndSetCreateCameraForm({ ...createCameraForm, description: e })
              }
              label={
                <span>
                  Description <span className="text-red-500">*</span>
                </span>
              }
              placeholder="Laundry Entrance Camera"
            />
          </div>
          <div className="my-4">
            <TextField
              value={createCameraForm.cameraSource}
              onChange={(e) =>
                validateAndSetCreateCameraForm({ ...createCameraForm, cameraSource: e.trim() })
              }
              label={
                <span>
                  Camera Source <span className="text-red-500">*</span>
                </span>
              }
              placeholder="https://app.visionify.ai/k8s/cameras.m3u8"
            />
          </div>
          {isSynologyIntegrationEnabled && (
            <div className="my-4">
              <TextField
                value={createCameraForm.token}
                onChange={(e) => validateAndSetCreateCameraForm({ ...createCameraForm, token: e })}
                label={<span>Synology Token</span>}
                placeholder="User Defined Token"
              />
            </div>
          )}
          {/* <div className="my-4">
            <TextField
              value={createCameraForm.thumbnailUrl}
              onChange={(e) =>
                validateAndSetCreateCameraForm({ ...createCameraForm, thumbnailUrl: e })
              }
              label={
                <span>
                  Camera Thumbnail URL<span className="text-red-500">*</span>
                </span>
              }
              placeholder="https://app.visionify.ai/k8s/cameras.m3u8"
            />
          </div> */}
          {invalidFieldsError && (
            <span className="text-red-500 text-sm mt-2">Please fill all the required fields.</span>
          )}
          {invalidSourceError && (
            <span className="text-red-500 text-sm mt-2">
              Please check the camera source URL. We only accept http, https, rtsp, rtmp, and rtmp
              protocols.
            </span>
          )}
          {addCameraError && (
            <span className="text-red-500 text-sm mt-2">
              * Unable to add camera. Please try again later.
            </span>
          )}
        </form>
      </Modal>
    </div>
  );
};

export default Cameras;
