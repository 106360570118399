/* eslint-disable */
import '../styles/ScenarioListing.css';
import React, { useCallback, useEffect, useState } from 'react';
import ScenarioCard from '../components/ScenarioCard';
import { getAppliedScenariosByCameraId, getScenarios } from '../api/scenarios.api';
import { Spinner, Tabs, Text } from '@shopify/polaris';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getCompanyById } from '../api/company.api';
import { useDispatch, useSelector } from 'react-redux';
import { setCompany, unsetCompany } from '../store/slices/companySlice';
import { resetScenarioFormData, resetScenarioPerCamFormData } from '../store/slices/scenarioSlice';

const segregateByCategory = (data) => {
  let scenarios = data.scenarios;
  const categories = {};
  scenarios
    .filter((scenario) => scenario.completed === true)
    .forEach((scenario) => {
      if (scenario.category_name in categories) {
        categories[scenario.category_name].push(scenario);
      } else {
        categories[scenario.category_name] = [scenario];
      }
    });
  return categories;
};

const constructTabs = (categories) => {
  return Object.keys(categories).map((category, idx) => {
    return {
      id: idx,
      content: category,
      panelID: `${category}-${idx}`
    };
  });
};

// TODO: Refactor this component to use the new useQuery hook
const ScenarioListing = () => {
  const { search } = useLocation();
  const navigateTo = useNavigate();

  // const navigateTo = useNavigate()
  let [searchParams] = useSearchParams();
  const [apiDataValue, setApiDataValue] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    const userId = searchParams.get('userId');
    const companyId = searchParams.get('companyId');

    if (userId && companyId) {
      const apiData = { user_id: userId, company_id: companyId };
      localStorage.setItem('apiData', JSON.stringify(apiData));
      setApiDataValue(apiData);
    }
    dispatch(resetScenarioFormData());
    dispatch(resetScenarioPerCamFormData());
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState({});
  const [categoryRefs, setCategoryRefs] = useState({});

  const [tabs, setTabs] = useState([]);
  const [selected, setSelected] = useState(0);
  const { user } = useSelector((state) => state.auth);

  const { data: companyData, isLoading: isCompanyLoading } = useQuery({
    queryKey: ['scenarios', apiDataValue],
    queryFn: () => getCompanyById(user?.company_id ?? 1)
  });

  const { data: appliedScenarioInfo, isLoading: areAppliedScenariosLoading } = useQuery({
    queryKey: ['appliedScenarios', searchParams.get('cameraId')],
    queryFn: () => getAppliedScenariosByCameraId(searchParams.get('cameraId')),
    enabled: !!searchParams.get('cameraId')
  });

  useEffect(() => {
    if (!isCompanyLoading) {
      if (companyData.success) {
        dispatch(setCompany(companyData.data));
      } else {
        dispatch(unsetCompany());
      }
    }
  }, [companyData, isCompanyLoading]);

  useEffect(() => {
    const fetchScenarios = async () => {
      try {
        setIsLoading(true);
        const data = await getScenarios();
        if (data) {
          const categoricalScenarios = segregateByCategory(data);
          setCategories(categoricalScenarios);
          setTabs(constructTabs(categoricalScenarios));
          const catRefs = Object.keys(categoricalScenarios).reduce((acc, category) => {
            acc[category] = React.createRef();
            return acc;
          }, {});
          setCategoryRefs(catRefs);
        }
      } catch (e) {
        console.log({ error: e });
      } finally {
        setIsLoading(false);
      }
    };
    fetchScenarios();
  }, []);

  const handleTabChange = useCallback(
    (selectedTabIndex) => {
      const category = tabs[selectedTabIndex].content;
      const categoryRef = categoryRefs[category];
      if (categoryRef && categoryRef.current) {
        smoothScroll(categoryRef.current, 50, 500);
      }
      setSelected(selectedTabIndex);
    },
    [categoryRefs]
  );

  const smoothScroll = (element, offset, duration) => {
    const targetPosition = element.getBoundingClientRect().top + window.pageYOffset - offset;
    const startPosition = window.pageYOffset;
    const distance = targetPosition - startPosition;
    let startTime = null;

    const animation = (currentTime) => {
      if (startTime === null) startTime = currentTime;
      const timeElapsed = currentTime - startTime;
      const nextScroll = ease(timeElapsed, startPosition, distance, duration);

      window.scrollTo(0, nextScroll);
      if (timeElapsed < duration) requestAnimationFrame(animation);
    };

    const ease = (t, b, c, d) => {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    requestAnimationFrame(animation);
  };

  const handleScroll = useCallback(() => {
    const offsets = Object.keys(categoryRefs)
      .map((category) => {
        if (categoryRefs[category].current) {
          return {
            category,
            offset: Math.abs(categoryRefs[category].current.getBoundingClientRect().top)
          };
        }
        return null;
      })
      .filter((item) => item !== null);

    if (offsets.length === 0) {
      return; // Exit the function if there are no offsets
    }

    // Find the category with the smallest offset (closest to the top)
    const closestCategory = offsets.reduce((prev, curr) => {
      if (!prev) return curr;
      return prev.offset < curr.offset ? prev : curr;
    }, null).category;

    // Find the index of this category in your tabs
    const tabIndex = tabs.findIndex((tab) => tab.content === closestCategory);
    if (tabIndex !== -1 && tabIndex !== selected) {
      setSelected(tabIndex);
    }
  }, [categoryRefs, tabs, selected]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  if (isLoading || isCompanyLoading) {
    return (
      <div
        style={{
          height: '100vh',
          width: '100wh',
          display: 'grid',
          placeItems: 'center'
        }}
      >
        <Spinner accessibilityLabel="Loading" size="large" color="teal" />
      </div>
    );
  }

  return (
    <div>
      <div className="sticky-tabs">
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange} />
      </div>
      <div className="px-4 mt-[25px]">
        {Object.keys(categories).map((category, idx) => {
          return (
            <div key={idx} ref={categoryRefs[category]}>
              <Text variant="headingLg" as="h5">
                {category}
              </Text>
              <div className="scenario-flex-layout">
                {categories[category].map((scenario, idx) => (
                  <ScenarioCard
                    navigateToSingleCamFlow={() => {
                      const searchObject = Object.fromEntries(new URLSearchParams(search));
                      if (appliedScenarioInfo?.data?.length > 0) {
                        const appliedScenario = appliedScenarioInfo.data.find((sc) => {
                          return `${sc.scenario_name}` === `${scenario.name}`;
                        });
                        if (appliedScenario) {
                          return navigateTo(
                            `/apply-scenario?camera=${searchObject.camera}&cameraId=${searchObject.cameraId}&scenario=${scenario.name}&appliedScenarioId=${appliedScenario.id}`
                          );
                        } else {
                          return navigateTo(
                            `/apply-scenario?camera=${searchObject.camera}&cameraId=${searchObject.cameraId}&scenario=${scenario.name}`
                          );
                        }
                      }
                      return navigateTo(
                        `/apply-scenario?camera=${searchObject.camera}&cameraId=${searchObject.cameraId}&scenario=${scenario.name}`
                      );
                    }}
                    key={idx}
                    scenario={scenario}
                  />
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ScenarioListing;
