import { useCallback, useEffect, useMemo, useState } from 'react';
import DatePickerPopover from '../../components/DatePicker';
import { Autocomplete, Button, Select, Tag } from '@shopify/polaris';
import { FilterMajor } from '@shopify/polaris-icons';
import { useQuery } from '@tanstack/react-query';
import { fetchEventDropdownData} from '../../api/events.api';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCamera,
  setSelectedDates,
  setSelectedReviewType,
  setSelectedScenarioEvents,
  setTag
} from '../../store/slices/eventSlice';

const AutoComplete = ({ availableOptions }) => {
  const deselectedOptions = useMemo(() => [...availableOptions], []);
  const [options] = useState(deselectedOptions);

  const dispatch = useDispatch();
  const { selectedScenarioEvents } = useSelector((state) => state.event);

  const [isAllSelected, setIsAllSelected] = useState(true);

  const updateSelection = useCallback(
    (selected) => {
      // let selectedValue;

      // eslint-disable-next-line no-unused-vars
      // options.forEach(({ title, options }) => {
      //   if (selectedValue) {
      //     return;
      //   }

      //   const matchedOption = options.find((option) => option.value.match(selected[0]));

      //   if (matchedOption) {
      //     selectedValue = matchedOption.label;
      //     dispatch(setSelectedScenarios([...new Set([...selectedScenarios, selectedValue])]));
      //   }
      // });

      if (isAllSelected) {
        if (selected.includes('All')) {
          setIsAllSelected(false);
          return dispatch(setSelectedScenarioEvents(selected.filter((e) => e !== 'All')));
        } else {
          setIsAllSelected(false);
          return dispatch(setSelectedScenarioEvents([]));
        }
      } else {
        if (selected.includes('All')) {
          setIsAllSelected(true);
          return dispatch(setSelectedScenarioEvents(['All']));
        } else {
          return dispatch(setSelectedScenarioEvents(selected));
        }
      }
    },
    [options, isAllSelected]
  );

  const textField = <Autocomplete.TextField placeholder="Select Event Types" autoComplete="off" />;

  return (
    <div>
      <Autocomplete
        textField={textField}
        allowMultiple
        selected={selectedScenarioEvents}
        options={options}
        onSelect={updateSelection}
      />
      <div className="overflow-x-auto whitespace-nowrap my-2 h-10">
        {selectedScenarioEvents &&
          selectedScenarioEvents.length > 0 &&
          selectedScenarioEvents.map((selected) => {
            return (
              <span key={selected} className="inline-block mx-1 my-2">
                <Tag>
                  {selected
                    .replace(/_/g, ' ')
                    .split(' ')
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ')}
                </Tag>
              </span>
            );
          })}
      </div>
      <style>
        {`
      .overflow-x-auto::-webkit-scrollbar {
        display: none;
      }
    `}
      </style>
    </div>
  );
};
const EventFilter = () => {
  const { data: eventDropdownData, isLoading: areFiltersLoading } = useQuery({
    queryKey: ['dropdown'],
    queryFn: fetchEventDropdownData
  });

  const [filter, setFilter] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [reviewOptions] = useState([
    {
      label: 'All',
      value: '2'
    },
    {
      label: 'Show Reviewed Events',
      value: '1'
    },
    {
      label: 'Show Unreviewed Events',
      value: '0'
    }
  ]);

  const eventsState = useSelector((state) => state.event);
  const dispatch = useDispatch();

  const handleCameraChange = useCallback((value) => dispatch(setCamera(value)), []);
  const handleTagChange = useCallback((value) => dispatch(setTag(value)), []);
  const handleDateChange = useCallback((value) => dispatch(setSelectedDates(value)), []);
  const handleReviewTypeChange = useCallback((value) => dispatch(setSelectedReviewType(value)), []);

  const toggleFilter = useCallback(() => {
    if (windowWidth < 1024) {
      setFilter(!filter);
    }
  }, [windowWidth, filter]);

  useEffect(() => {
    if (windowWidth > 1024) {
      setFilter(true);
    } else setFilter(false);
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [windowWidth]);

  if (areFiltersLoading) return <div className="h-auto w-[80vw] grid place-items-center"></div>;

  return (
    <div>
      <div className="bg-white rounded-b-md p-4 shadow-md">
        <div className="title flex items-center justify-between">
          <div className="lg:hidden block">
            <Button onClick={toggleFilter} icon={FilterMajor}>
              Filter
            </Button>
          </div>
        </div>

        <div>
          {filter && (
            <div className="py-2 grid xl:grid-cols-5 lg:grid-cols-5 md:grid-cols-3 grid-cols-1 gap-5">
              <div className="space-y-1">
                <label>Scenario</label>
                <AutoComplete availableOptions={eventDropdownData?.scenarios ?? []} />
              </div>
              <Select
                label="Cameras"
                options={eventDropdownData?.cameras ?? []}
                onChange={handleCameraChange}
                value={eventsState.selectedCamera}
              />
              <Select
                label="Tags"
                options={eventDropdownData?.tags ?? []}
                onChange={handleTagChange}
                value={eventsState.selectedTag}
              />
              <Select
                label="Review Status"
                options={reviewOptions}
                onChange={handleReviewTypeChange}
                value={eventsState.selectedReviewType}
              />
              <div>
                <DatePickerPopover
                  selectedDates={eventsState.selectedDates}
                  setSelectedDates={handleDateChange}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventFilter;
