import { Card } from '@shopify/polaris';
import IncidentLog from './IncidentLog';
import BarChart from './SeverityChart';

const Severity = ({
  title,
  isLoading,
  data,
  events,
  areEventsLoading,
  incidentLogPageNumber,
  setIncidentLogPageNumber,
  severityTypeFilter,
  setSeverityTypeFilter,
  reference
}) => {
  return (
    <div>
      <Card>
        <div className="flex justify-between flex-wrap items-center pb-4 border-b lg:space-y-0 space-y-5">
          <h3 className="font-semibold text-[20px]">{title} Events</h3>
        </div>
        <div className="statistics pt-4 grid grid-cols-1 md:grid-cols-1 xl:grid-cols-4 gap-5">
          <div className="xl:col-span-2 2xl:col-span-1">
            <div className="">
              <BarChart
                reference={reference}
                isLoading={isLoading}
                data={data}
                severityTypeFilter={severityTypeFilter}
                setSeverityTypeFilter={setSeverityTypeFilter}
              />
            </div>
          </div>
          <div className="xl:col-span-2 2xl:col-span-3">
            <div className="h-full">
              <IncidentLog
                events={events}
                areEventsLoading={areEventsLoading}
                incidentLogPageNumber={incidentLogPageNumber}
                setIncidentLogPageNumber={setIncidentLogPageNumber}
              />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Severity;
