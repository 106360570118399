import { 
  Text,
  Box,
  Button,
  Icon
} from '@shopify/polaris';
import { CancelSmallMinor } from '@shopify/polaris-icons';
import { useState, useEffect } from 'react';

const POSITIONS = {
  topLeft: { top: '16px', left: '16px' },
  topRight: { top: '2px', right: '16px' },
  bottomLeft: { bottom: '16px', left: '16px' },
  bottomRight: { bottom: '16px', right: '16px' }
};

const COLORS = {
  success: { bg: 'success-subdued', text: 'success' },
  warning: { bg: 'warning-subdued', text: 'warning' },
  critical: { bg: 'critical-subdued', text: 'critical' },
  info: { bg: 'info-subdued', text: 'info' }
};

export const NotificationMessage = ({ 
  text,
  position = 'topRight',
  color = 'info',
  children 
}) => {
  const [isVisible, setIsVisible] = useState(true);
   

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (!isVisible) return null;

  return (
    
    <div style={{ 
      position: 'fixed',
      zIndex: 1000,
      ...POSITIONS[position],
      transition: 'all 0.3s ease-in-out',
      opacity: isVisible ? 1 : 0,
      transform: isVisible ? 'translateY(0)' : 'translateY(-20px)',
      minWidth: '300px',
      maxWidth: '400px'
    }}>
     <Box
      padding="3"
      background={COLORS[color]?.bg || 'subdued'}
      borderRadius="2"
      shadow="deep"
    >
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '12px',
        backgroundColor: 'white',
        padding: '12px',
        borderRadius: '6px',
        boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
      }}>
        <div style={{
          flex: 1,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }}>
          <Text as="p" variant="bodyMd" tone={COLORS[color]?.text || 'default'}>
            {text}
          </Text>
          {children}
        </div>
        <Button
          plain
          onClick={() => setIsVisible(false)}
          accessibilityLabel="Close notification"
        >
          <Icon 
            source={CancelSmallMinor}
            tone={COLORS[color]?.text || 'base'}
          />
        </Button>
      </div>
    </Box>
    </div>
  );
};