import { api } from './index';
const resource = '/images';

export const uploadBase64Image = async (base64Image) => {
  try {
    const response = await api.post(`${resource}/upload-base64`, {
      base64Image
    });
    return response.data;
  } catch (e) {
    throw new Error(e);
  }
};
