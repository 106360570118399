import { Spinner } from '@shopify/polaris';
import { useMutation } from '@tanstack/react-query';
import { notification } from 'antd';
import React, { useState, useRef, useEffect } from 'react';
import { Circle, Image, Layer, Stage, Text } from 'react-konva';
import { Link } from 'react-router-dom';
import useImage from 'use-image';
import { getEventCountByCameraId } from '../api/events.api';
// import { getActiveCameraIconDataUrl, getCameraIconDataUrl } from '../utils';

export function ReadOnlyKonvaCanvas({
  initialIcons,
  backgroundImageUrl,
  canvasSize,
  selectedDates,
  selectedFilters,
  reference
}) {
  const [api, contextHolder] = notification.useNotification();
  const [image] = useImage(backgroundImageUrl, 'anonymous');
  const [hoveredCircle, setHoveredCircle] = useState(null); // Track which circle is hovered

  const [combobox, setCombobox] = useState({
    visible: false,
    x: 0,
    y: 0,
    name: '',
    count: 0,
    id: 0
  });
  const comboboxRef = useRef(null);

  const [chosenIcon, setChosenIcon] = useState({
    visible: false,
    x: 0,
    y: 0,
    name: '',
    count: 0,
    id: 0
  });

  const { mutate: getEventCountForCamera, isPending } = useMutation({
    mutationKey: ['getEventCountForSitemap'],
    mutationFn: getEventCountByCameraId,
    onSuccess: (data) => {
      setCombobox({
        visible: true,
        x: chosenIcon.x,
        y: chosenIcon.y,
        name: chosenIcon.name,
        id: chosenIcon.id,
        count: data?.data?.count ?? 0
      });
    },
    onError: () => {
      api.error({ message: 'Something went wrong! Please try again later.' });
    }
  });

  const handleIconClick = (icon) => {
    getEventCountForCamera({
      id: icon.id,
      payload: {
        scenario: selectedFilters.scenario,
        timeRange: selectedFilters.timeRange,
        startDate: selectedDates.start,
        endDate: selectedDates.end
      }
    });
    setChosenIcon({
      visible: true,
      x: icon.x,
      y: icon.y,
      name: icon.name,
      count: 0,
      id: icon.id
    });
  };

  const handleComboboxClick = () => {
    setCombobox({ visible: false, x: 0, y: 0, name: '', count: 0, id: 0 });
  };

  const handleClickOutside = (event) => {
    if (comboboxRef.current && !comboboxRef.current.contains(event.target)) {
      setCombobox({ visible: false, x: 0, y: 0, name: '', count: 0, id: 0 });
      setChosenIcon({ visible: false, x: 0, y: 0, name: '', count: 0, id: 0 });
    }
  };

  const getRadius = (count, isHovered) => {
    // Define a base radius and scale it with the count
    const baseRadius = 10;
    const maxRadius = 50; // Set the maximum radius size
    const scaledRadius = baseRadius + Math.sqrt(count) * 5; // Adjust the scaling factor as needed
    const radius = Math.min(scaledRadius, maxRadius); // Clamp the radius to the maximum value
    return isHovered ? radius * 1.2 : radius; // Increase radius by 20% on hover, respecting the maximum value
  };

  useEffect(() => {
    if (combobox.visible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [combobox.visible]);

  return (
    <div style={{ position: 'relative' }}>
      {contextHolder}
      <Stage width={canvasSize.width} height={canvasSize.height} ref={reference}>
        <Layer>
          <Image image={image} height={canvasSize.height} width={canvasSize.width} />
          {initialIcons.map((icon, i) => {
            const radius = getRadius(icon.count, hoveredCircle === i);
            const textWidth = icon.name.length * 7; // Approximate width of the text
            return (
              <>
                <Circle
                  key={i}
                  x={icon.x}
                  y={icon.y}
                  radius={radius}
                  fill="blue"
                  opacity={hoveredCircle === i ? 1 : 0.5} // Change opacity based on hover state
                  onClick={() => handleIconClick(icon)}
                  onMouseEnter={() => setHoveredCircle(i)}
                  onMouseLeave={() => setHoveredCircle(null)}
                />
                <Text
                  x={icon.x - textWidth / 2} // Center the text horizontally under the circle
                  y={icon.y + radius + 5} // Position the text just below the circle
                  text={icon.name}
                  fontSize={14}
                  fill="black" // Adjust color as needed
                  align="center"
                />
              </>
            );
          })}
        </Layer>
      </Stage>
      {combobox.visible && (
        <div
          ref={comboboxRef}
          style={{
            position: 'absolute',
            top: combobox.y + getRadius(combobox.count, false) + 10, // Position below the circle and text
            left: combobox.x - 50, // Center it horizontally around the x position
            backgroundColor: 'white',
            border: '1px solid black',
            borderRadius: '4px',
            padding: '10px',
            zIndex: 1000
          }}
        >
          <Link
            to={`/cameras/${combobox.id}`}
            onClick={handleComboboxClick}
            className="cursor-pointer mb-[5px] text-blue-600 underline hover:text-blue-800"
          >
            {combobox.name}
          </Link>
          {isPending ? (
            <Spinner size="small" />
          ) : (
            <div className="text-black">{combobox.count} Events</div>
          )}
        </div>
      )}
    </div>
  );
}
