import { Card, Spinner } from '@shopify/polaris';
import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, BarElement, LinearScale, Title, Legend } from 'chart.js';
import { Icons } from '../../Icons';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Legend);

function BarChart({ isLoading, data, hideEvents, reference }) {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: '',
        data: [],
        hoverOffset: 2,
        maxBarThickness: 40,
        borderRadius: 8
      }
    ]
  });

  useEffect(() => {
    if (data) {
      setChartData((prevData) => ({
        ...prevData,
        labels: data?.data?.labels ?? [],
        datasets: [
          {
            ...prevData.datasets[0],
            data: data?.data?.values ?? []
          }
        ]
      }));
    }
  }, [data]);

  useEffect(() => {
    if (reference.current) {
      const chart = reference.current;
      const ctx = chart.ctx;
      const gradient = ctx.createLinearGradient(0, 0, 0, 400);
      gradient.addColorStop(0, 'rgba(225, 202, 255, 1)');
      gradient.addColorStop(1, 'rgba(156, 106, 222, 1)');

      setChartData((prevData) => ({
        ...prevData,
        datasets: prevData.datasets.map((dataset) => ({
          ...dataset,
          backgroundColor: gradient
        }))
      }));
    }
  }, [reference.current, data]);

  const barchartoptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: true
      }
    },
    scales: {
      x: {
        grid: {
          display: false,
          borderDash: [3, 3],
          borderDashOffset: 2
        }
      },
      y: {
        grid: {
          color: 'rgba(0, 0, 0, 0.1)',
          borderDash: [3, 3],
          borderDashOffset: 2
        },
        ticks: {
          stepSize: 30
        }
      }
    }
  };

  return (
    <Card>
      {isLoading || !data ? (
        <div className="flex justify-center items-center" style={{ height: '400px' }}>
          <Spinner accessibilityLabel="Loading chart" size="large" />
        </div>
      ) : (
        <div>
          {!hideEvents && (
            <>
              <div className="flex items-center justify-between">
                <h3 className="font-semibold text-[18px] pb-3 pt-2 pl-2">Events Overview</h3>
              </div>
              <div className="flex items-center gap-10 px-2 py-3">
                <div>
                  <h3 className="text-[18px] font-semibold">{data?.totalEvents?.value ?? 0}</h3>
                  <p className="text-[12px] text-[#616161] pt-1">Total Events</p>
                </div>
                <div>
                  {data && data?.totalEvents?.trend === 'POSITIVE' ? (
                    <h3 className="text-[18px] font-semibold flex gap-4">
                      {Number(data?.totalEvents?.trendValue).toFixed(2) ?? 0}%{' '}
                      <Icons.upwardArrow className="h-4" fill="#E51C00" />
                    </h3>
                  ) : (
                    <h3 className="text-[18px] font-semibold flex gap-4">
                      {Number(data?.totalEvents?.trendValue).toFixed(2) ?? 0}%{' '}
                      <Icons.downwardArrow className="h-4" fill="#45A636" />
                    </h3>
                  )}
                  <p className="text-[12px] text-[#616161] pt-1">Average Trend</p>
                </div>
              </div>
            </>
          )}
          <div className="bar-chart-container mx-[10px] py-5 pb-0" style={{ height: '225px' }}>
            <Bar ref={reference} data={chartData} options={barchartoptions} />
          </div>
        </div>
      )}
    </Card>
  );
}

export default BarChart;
