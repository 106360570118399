import { Card } from '@shopify/polaris';
import React from 'react';
import BarChart from '../../components/charts/Bar';

const BarGraph = ({ isLoading, data, reference }) => {
  return (
    <>
      <div className="mb-4">
        <Card>
          <div className="statistics pt-4 grid grid-cols-1 md:grid-cols-1 xl:grid-cols-4 gap-5">
            <div className="xl:col-span-4">
              <div>
                <BarChart reference={reference} isLoading={isLoading} data={data} />
              </div>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default BarGraph;
